import EaseIM from '@/IM/initwebsdk';
import {
  handleSDKErrorNotifi,
  setMessageKey,
  createMessage,
} from '@/utils/handleSomeData';
import _ from 'lodash';
// import { ref, toRaw } from 'vue';
import { messageType } from '@/constant';
import { usePlayRing } from '@/hooks';
import axios from 'axios';
const { ALL_MESSAGE_TYPE } = messageType;
const Message = {
  state: {
    messageList: {},
  },
  mutations: {
    UPDATE_MESSAGE_LIST: (state, msgBody) => {
      const toUpdateMsgList = _.assign({}, state.messageList);
      //这里是获得医生账号 loginUserFromStorage.user
      // const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
      // const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
      // if(loginUserFromStorage.user != msgBody.from){
      //   // //后台接口 与之聊过的用户列表
      //   var url = 'https://app.guoyiangyuan.com/api/v1/63563fe70cf71'
      //   axios.post(url,{
      //     doctor_hx_id : loginUserFromStorage.user,
      //     user_hx_id : msgBody.from
      //   })
      //   .then(function (response) {
      //     console.log(response,'真实与医生聊过的用户列表 - 右侧聊天记录列表')
      //     if(response.data.code == 1){
      //       // state.messageList = response.data.data;//替换数据库的内容!
      //       // toUpdateMsgList = _.assign(response.data.data, state.messageList);
      //     }
      //   })

      // }
      
      const listKey = setMessageKey(msgBody);
      if (!toUpdateMsgList[listKey]) {
        toUpdateMsgList[listKey] = [];
        _.unionBy(toUpdateMsgList[listKey].push(msgBody), m => m.id);
      } else {
        _.unionBy(toUpdateMsgList[listKey].push(msgBody), m => m.id);
      }
      // toUpdateMsgList = "接口返回"
      console.log("操作聊天记录信息的",msgBody.from)
      
      state.messageList = toUpdateMsgList
      
      // console.log("操作聊天记录信息的",toUpdateMsgList)
    },
    UPDATE_HISTORY_MESSAGE: (state, payload) => {
      const { listKey, historyMessage } = payload;
      const toUpdateMsgList = _.assign({}, state.messageList);
      if (!toUpdateMsgList[listKey]) {
        toUpdateMsgList[listKey] = [];
        _.unionBy(toUpdateMsgList[listKey].push(...historyMessage), m => m.id);
      } else {
        _.unionBy(toUpdateMsgList[listKey].unshift(...historyMessage), m => m.id);
      }
      state.messageList = toUpdateMsgList;
    },
    //清除某条会话消息
    CLEAR_SOMEONE_MESSAGE: (state, payload) => {
      state.messageList[payload] = [];
    },
    //撤回删除消息
    CHANGE_MESSAGE_BODAY: (state, payload) => {
      const { type, key, mid } = payload;
      if (type === 'recall') {
        if (state.messageList[key]) {
          let res = _.find(state.messageList[key], (o) => o.id === mid);
          res.isRecall = true;
        }
      }
      if (type === 'deleteMsg') {
        if (state.messageList[key]) {
          let sourceData = state.messageList[key];
          let index = _.findIndex(state.messageList[key], (o) => o.id === mid);
          sourceData.splice(index, 1);
          state.messageList[key] = _.assign([], sourceData);
        }
      }
    },
  },
  actions: {
    //添加新消息
    createNewMessage: ({ dispatch, commit }, params) => {
      const { isOpenPlayRing, playRing } = usePlayRing()
      let key = setMessageKey(params);
      commit('UPDATE_MESSAGE_LIST', params);
      //目前根据全局配置进行新消息声音提示，后续计划根据会话级别可进行设置是否声音提示，比如设定免打扰。
      if (isOpenPlayRing.value) playRing()
      dispatch('gatherConversation', key);
    },
    //获取历史消息
    getHistoryMessage: async ({ dispatch, commit }, params) => {
      const { id, chatType, cursor } = params;
      return new Promise(async (resolve, reject) => {
        let options = {
          targetId: id,
          pageSize: 10,
          cursor: cursor,
          chatType: chatType,
          searchDirection: 'up',
        };
        try {
          let { cursor, messages } = await EaseIM.conn.getHistoryMessages(
            options
          );
          messages.length > 0 &&
            messages.forEach((item) => {
              item.read = true;
            });
          resolve({ messages, cursor });
          commit('UPDATE_HISTORY_MESSAGE', {
            listKey: id,
            historyMessage: _.reverse(messages),
          });
          //提示会话列表更新
          dispatch('gatherConversation', id);
        } catch (error) {
          reject(error);
        }
      });
    },
    //发送展示类型消息
    sendShowTypeMessage: async ({ dispatch, commit }, params) => {

      return new Promise(async (resolve, reject) => {
        //主要作用为创建消息Options中附件会有上传失败的回调函数。
        //传入errorCallback，让附件类型消息在上传失败时调用reject抛出error
        const errorCallback = (error) => { reject(error) }
        console.log('>>>>发送成功前params', params);
        // let local_url = params.msgOptions.img_local_src;//需要传到后台的文件
        let options = createMessage.createOptions(params, errorCallback);
        
        // console.log('>>>>发送成功前options', options);
        let msg = EaseIM.message.create(options);
        try {
          let { serverMsgId } = await EaseIM.conn.send(msg);
          console.log('>>>>发送成功', msg);
          let message = msg
          // message.from = message.to
          // let message = JSON.parse(JSON.stringify(msg))
          
          // console.log(message)
          // console.log(message.from)
          // return false
          /*****存缓存本机浏览器聊天记录BEGIN****/
          console.log(">>>存缓存本机浏览器聊天记录BEGIN*")
          const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
          const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
          message.from = loginUserFromStorage.user
          var this_user_me_key = loginUserFromStorage.user+'-history-list'
          var this_user_with_me_sto = window.localStorage.getItem(this_user_me_key)
          // console.log("这个用户与我的本地缓存聊天记录",this_user_with_me_sto)
          //设置缓存聊天记录
          if(this_user_with_me_sto){
            //有聊天记录 存到那个人的名下插入
            console.log(JSON.parse(this_user_with_me_sto),'this_user_with_me_sto')
            var this_history_user = JSON.parse(this_user_with_me_sto)
            var end_old_arr = {};
            console.log(this_history_user[message.to],"是否有这个人的聊天记录 无则新增 有则追加")
            if(this_history_user[message.to]){ //若为这个用户的聊天记录 插入 不能把其他人的数据给删除掉
              let this_content = this_history_user[message.to] //原来的缓存聊天列表
              console.log(this_content,'原来的缓存聊天列表')
              console.log(this_content[0],'原来的缓存聊天列表')
              this_content.push(...[message])
              console.log(this_content,"push后 这个人的聊天列表")
              this_history_user[message.to] = this_content
              end_old_arr = this_history_user;
              console.log(end_old_arr,"已经存在的最后")
            }else{
              //若为新人 则追加原有数组以后保存
              let old = this_history_user
              var old_arr = {};
              for (let i in old) {
                old_arr[i] = old[i];//旧聊天记录根据人分组列表
              }
              //新
              let this_user_key = message.to //新用户id
              old_arr[this_user_key] = [message];//新用户聊天记录内容

              end_old_arr = old_arr
            }
            console.log(end_old_arr,"这是循环后的需要存入缓存的列表")
            console.log(JSON.stringify(end_old_arr),"这是循环后的需要存入缓存的列表")
            window.localStorage.setItem(this_user_me_key, JSON.stringify(end_old_arr))
            console.log(">>>存缓存本机浏览器聊天记录END*")
            /*****存缓存本机浏览器聊天记录END****/
          }else{
            //无则直接插入这个新人聊天记录
            let this_user_key = message.to
            let this_content = {}
            this_content[this_user_key] =  [message];
            let this_content_end = JSON.stringify(this_content)
            window.localStorage.setItem(this_user_me_key, this_content_end)
          }






          msg.id = serverMsgId;
          msg.from = EaseIM.conn.user;
          let msgBody = createMessage.createMsgBody(msg);
          commit('UPDATE_MESSAGE_LIST', msgBody);
          // 提示会话列表更新
          dispatch('gatherConversation', msgBody.to);
          resolve('OK');
          //入库
          // chatType: "singleChat"
          // ext: undefined
          // from: "wangyisheng"
          // id: "1069995773661283712"
          // isChatThread: undefined
          // msg: "这是客服发送"
          // msgConfig: undefined
          // time: 1666692604683
          // to: "user_146_28a0139b712a6837c474a716a381005c"
          // type: "txt"
          //根据客服发送消息的类型 请求相应接口
          var ruku_url = 'https://app.xiaoqiaodayi.com/api/v1/63f87b6ea12bf';
          var upload_upload_im_or_yuyin_url = 'https://app.xiaoqiaodayi.com/api/v1/5d5fa8984f0c2';
          var _msg = {
            msgBody: msgBody
          }
          if(msgBody.type == "txt"){
            //客服发送消息入库 文字
            axios.post(ruku_url,{
              msg : JSON.stringify(_msg)
            })
            .then(function (response) {
              console.log(response,'医生发送消息入库文字')
            })
          }else if(msgBody.type == "img"){
            let local_url = options.file.data;//需要传到后台的文件
            //参考下前端传的都是什么? 在哪里上传图片 返回本地图片id
            //客服发送消息入库 图片
            // _msg.msgBody
            // 构建FormData对象,通过该对象存储要上传的文件
            const formData = new FormData();
            // 遍历当前临时文件List,将上传文件添加到FormData对象中
            formData.append('file', local_url);
            // 调用后端接口,发送请求
            axios.post(upload_upload_im_or_yuyin_url, formData, {
              // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
              headers: {
                // 表示上传的是文件,而不是普通的表单数据
                'Content-Type': 'multipart/form-data'
              }
            }).then(function (res) {
              console.log(res,'图片上传成功')
              if(res.data.code == "1"){
                var img_id = res.data.data[0].id
                var thumb = res.data.data[0].thumb
                var url = res.data.data[0].path
                // console.log(img_id, thumb, url,"图片图片图片");
                _msg.msgBody.url = url
                _msg.msgBody.thumb = thumb
                _msg.msgBody.img_id = img_id
                //入库
                axios.post(ruku_url,{
                  msg : JSON.stringify(_msg)
                })
                .then(function (response) {
                  console.log(response,'医生发送消息入库图片')
                })
              }
            })
          }else if(msgBody.type == "audio"){
            //参考下前端传的都是什么? 在哪里上传语音文件 但是后台不用发不语音 如果可以更好
            //客服发送消息入库 语音
            // _msg.msgBody
            // 构建FormData对象,通过该对象存储要上传的文件
            const formData = new FormData();
            // 遍历当前临时文件List,将上传文件添加到FormData对象中
            formData.append('file', local_url);
            // 调用后端接口,发送请求
            axios.post(upload_upload_im_or_yuyin_url, formData, {
              // 因为我们上传了图片,因此需要单独执行请求头的Content-Type
              headers: {
                // 表示上传的是文件,而不是普通的表单数据
                'Content-Type': 'multipart/form-data'
              }
            }).then(function (res) {
              console.log(res,'语音上传成功')
              if(res.data.code == "1"){
                // var img_id = res.data.data[0].id
                // var thumb = res.data.data[0].thumb
                // var url = res.data.data[0].path
                // // console.log(img_id, thumb, url,"语音语音语音");
                // _msg.msgBody.url = url
                // _msg.msgBody.thumb = thumb
                // _msg.msgBody.img_id = img_id
                // //入库
                // axios.post(ruku_url,{
                //   msg : JSON.stringify(_msg)
                // })
                // .then(function (response) {
                //   console.log(response,'医生发送消息入库语音')
                // })
              }

            })
          }
          


        } catch (error) {
          console.log('>>>>>>>>发送失败', error);
          handleSDKErrorNotifi(error.type, error.message);
        }
      });
    },
    //添加通知类消息
    createInformMessage: ({ dispatch, commit }, params) => {
      let msgBody = _.cloneDeep(params)
      msgBody.type = ALL_MESSAGE_TYPE.INFORM
      let key = setMessageKey(params);
      console.log('>>>>>>添加系统消息', params);
      commit('UPDATE_MESSAGE_LIST', msgBody);
      dispatch('gatherConversation', key);
    },
    //撤回消息
    recallMessage: async ({ dispatch, commit }, params) => {
      const { mid, to, chatType } = params;
      return new Promise(async (resolve, reject) => {
        try {
          await EaseIM.conn.recallMessage({ mid, to, chatType });
          commit('CHANGE_MESSAGE_BODAY', { type: 'recall', key: to, mid });
          dispatch('gatherConversation', to);
          resolve('OK');
        } catch (error) {
          handleSDKErrorNotifi(error.type, error.message);
          console.log('>>>>>>撤回消息error', error);
          reject(error);
        }
      });
    },
  },
};
export default Message;
