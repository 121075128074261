<script setup>
import { useStore } from 'vuex';
import router from '@/router';
import EaseIM from '@/IM/initwebsdk';
import { handleSDKErrorNotifi, setMessageKey } from '@/utils/handleSomeData';
import { informType } from '@/constant'
import { usePlayRing } from '@/hooks'
// import ring from '@/assets/ring.mp3'
import ring from '@/assets/adou.mp3'

const store = useStore();
const { isOpenPlayRing, clickRing } = usePlayRing()
EaseIM.logger.disableAll()
/* connect 相关监听 */
EaseIM.conn.addEventHandler('connection', {
  onConnected: () => {
    console.log('>>>>>环信连接成功');
    store.commit('CHANGE_LOGIN_STATUS', true);
    if (isOpenPlayRing.value) clickRing()
    fetchLoginUsersInitData()
    router.replace('/chat');
  },
  onDisconnected: () => {
    router.push('/login');
    store.commit('CHANGE_LOGIN_STATUS', false);
  },
  onOnline: () => {
    store.commit('CHANGE_NETWORK_STATUS', true)
  },                  // 本机网络连接成功。
  onOffline: () => {
    store.commit('CHANGE_NETWORK_STATUS', false)
  },                 // 本机网络掉线。
  onError: (error) => {
    console.log('on error', error);
    handleSDKErrorNotifi(error.type, error.message);
  },
});
//fetch 登陆用户的初始数据
const fetchLoginUsersInitData = () => {
  getMyUserInfos()
  fetchFriendList()
  fetchTheLoginUserBlickList()
  fetchGroupList()
  //初始化vuex中的会话列表相关数据
  store.commit('INIT_CONVERSATION_STATE')
}
//获取登陆用户属性
const getMyUserInfos = () => {
  const userId = EaseIM.conn.user;
  store.dispatch('getMyUserInfo', userId);
};
//获取好友列表
const fetchFriendList = () => {
  // const { value = {} } = useLocalStorage('friendList')
  // if (Object.values(JSON.parse(value)).length > 0) return
  store.dispatch('fetchFriendList')
}
//获取黑名单列表
const fetchTheLoginUserBlickList = () => store.dispatch('fetchBlackList')
//获取加入的群组列表
const fetchGroupList = () => {
  //如果本地存储里不存在群组列表则调用获取群组列表
  // const { value = {} } = useLocalStorage('groupList')
  // if (Object.values(JSON.parse(value)).length > 0) return
  const pageParams = {
    pageNum: 1,
    pageSize: 20,
  }
  store.dispatch('fetchGroupList', pageParams)
}
//在线状态订阅相关
const presenceStatus = (type, user) => {
  type === 'sub' && store.dispatch('subFriendsPresence', [user])
  type === 'unsub' && store.dispatch('unsubFriendsPresence', [user])
}

/* presence 相关监听 */
EaseIM.conn.addEventHandler('presenceStatusChange', {

  onPresenceStatusChange: (status) => {
    console.log('>>>>>presenceStatusChange', status)
    getUserPresence(...status);
  },

});
//处理登陆用户状态的变更
const getUserPresence = (status) => {
  store.dispatch('handlePresenceChanges', status);
};
/* message 相关监听 */
EaseIM.conn.addEventHandler('messageListen', {
  onTextMessage: function (message) {
    console.log('>>>>>>>App mesage', message)
    console.log('setMessageKey', setMessageKey(message))
    pushNewMessage(message)
    //确保发送之后 存入此医生与此用户的聊天记录
    /*****存缓存本机浏览器聊天记录BEGIN****/
    console.log(">>>存缓存本机浏览器聊天记录BEGIN*")
    const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
    const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
    var this_user_me_key = loginUserFromStorage.user+'-history-list'
    var this_user_with_me_sto = window.localStorage.getItem(this_user_me_key)
    // console.log("这个用户与我的本地缓存聊天记录",this_user_with_me_sto)
    //设置缓存聊天记录
    if(this_user_with_me_sto){
      //有聊天记录 存到那个人的名下插入
      console.log(JSON.parse(this_user_with_me_sto),'this_user_with_me_sto')
      var this_history_user = JSON.parse(this_user_with_me_sto)
      var end_old_arr = {};
      // console.log(this_history_user[message.from],"是否有这个人的聊天记录 无则新增 有则追加")
      if(this_history_user[message.from]){ //若为这个用户的聊天记录 插入 不能把其他人的数据给删除掉
        let this_content = this_history_user[message.from] //原来的缓存聊天列表
        // console.log(this_content,'原来的缓存聊天列表')
        // console.log(this_content[0],'原来的缓存聊天列表')
        this_content.push(...[message])
        // console.log(this_content,"push后 这个人的聊天列表")
        this_history_user[message.from] = this_content
        end_old_arr = this_history_user;
        // console.log(end_old_arr,"已经存在的最后")
      }else{
        //若为新人 则追加原有数组以后保存
        let old = this_history_user
        var old_arr = {};
        for (let i in old) {
          old_arr[i] = old[i];//旧聊天记录根据人分组列表
        }
        //新
        let this_user_key = message.from //新用户id
        old_arr[this_user_key] = [message];//新用户聊天记录内容

        end_old_arr = old_arr
      }
      // console.log(end_old_arr,"这是循环后的需要存入缓存的列表")
      // console.log(JSON.stringify(end_old_arr),"这是循环后的需要存入缓存的列表")
      window.localStorage.setItem(this_user_me_key, JSON.stringify(end_old_arr))
      console.log(">>>存缓存本机浏览器聊天记录END*")
    }else{
      //无则直接插入这个新人聊天记录
      let this_user_key = message.from
      let this_content = {}
      this_content[this_user_key] =  [message];
      let this_content_end = JSON.stringify(this_content)
      window.localStorage.setItem(this_user_me_key, this_content_end)
    }
    /*****存缓存本机浏览器聊天记录END****/
    
  },    // 收到文本消息。
  onEmojiMessage: function (message) {
    pushNewMessage(message)
  },   // 收到表情消息。
  onImageMessage: function (message) {
    pushNewMessage(message)
    //确保发送之后 存入此医生与此用户的聊天记录
    /*****存缓存本机浏览器聊天记录BEGIN****/
    console.log(">>>图片 - 存缓存本机浏览器聊天记录BEGIN*")
    const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
    const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
    var this_user_me_key = loginUserFromStorage.user+'-history-list'
    var this_user_with_me_sto = window.localStorage.getItem(this_user_me_key)
    // console.log("这个用户与我的本地缓存聊天记录",this_user_with_me_sto)
    //设置缓存聊天记录
    if(this_user_with_me_sto){
      //有聊天记录 存到那个人的名下插入
      console.log(JSON.parse(this_user_with_me_sto),'this_user_with_me_sto')
      var this_history_user = JSON.parse(this_user_with_me_sto)
      var end_old_arr = {};
      // console.log(this_history_user[message.from],"是否有这个人的聊天记录 无则新增 有则追加")
      if(this_history_user[message.from]){ //若为这个用户的聊天记录 插入 不能把其他人的数据给删除掉
        let this_content = this_history_user[message.from] //原来的缓存聊天列表
        // console.log(this_content,'原来的缓存聊天列表')
        // console.log(this_content[0],'原来的缓存聊天列表')
        this_content.push(...[message])
        // console.log(this_content,"push后 这个人的聊天列表")
        this_history_user[message.from] = this_content
        end_old_arr = this_history_user;
        // console.log(end_old_arr,"已经存在的最后")
      }else{
        //若为新人 则追加原有数组以后保存
        let old = this_history_user
        var old_arr = {};
        for (let i in old) {
          old_arr[i] = old[i];//旧聊天记录根据人分组列表
        }
        //新
        let this_user_key = message.from //新用户id
        old_arr[this_user_key] = [message];//新用户聊天记录内容

        end_old_arr = old_arr
      }
      // console.log(end_old_arr,"这是循环后的需要存入缓存的列表")
      // console.log(JSON.stringify(end_old_arr),"这是循环后的需要存入缓存的列表")
      window.localStorage.setItem(this_user_me_key, JSON.stringify(end_old_arr))
    }else{
      //无则直接插入这个新人聊天记录
      let this_user_key = message.from
      let this_content = {}
      this_content[this_user_key] =  [message];
      let this_content_end = JSON.stringify(this_content)
      window.localStorage.setItem(this_user_me_key, this_content_end)
    }
    console.log(">>>图片 - 存缓存本机浏览器聊天记录END*")
    /*****存缓存本机浏览器聊天记录END****/
  },   // 收到图片消息。
  onCmdMessage: function (message) {
    console.log('>>>>>收到命令消息', message)
  },     // 收到命令消息。
  onAudioMessage: function (message) {
    pushNewMessage(message)
    //确保发送之后 存入此医生与此用户的聊天记录
    /*****存缓存本机浏览器聊天记录BEGIN****/
    console.log(">>>语音 - 存缓存本机浏览器聊天记录BEGIN*")
    const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
    const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
    var this_user_me_key = loginUserFromStorage.user+'-history-list'
    var this_user_with_me_sto = window.localStorage.getItem(this_user_me_key)
    // console.log("这个用户与我的本地缓存聊天记录",this_user_with_me_sto)
    //设置缓存聊天记录
    if(this_user_with_me_sto){
      //有聊天记录 存到那个人的名下插入
      console.log(JSON.parse(this_user_with_me_sto),'this_user_with_me_sto')
      var this_history_user = JSON.parse(this_user_with_me_sto)
      var end_old_arr = {};
      // console.log(this_history_user[message.from],"是否有这个人的聊天记录 无则新增 有则追加")
      if(this_history_user[message.from]){ //若为这个用户的聊天记录 插入 不能把其他人的数据给删除掉
        let this_content = this_history_user[message.from] //原来的缓存聊天列表
        // console.log(this_content,'原来的缓存聊天列表')
        // console.log(this_content[0],'原来的缓存聊天列表')
        this_content.push(...[message])
        // console.log(this_content,"push后 这个人的聊天列表")
        this_history_user[message.from] = this_content
        end_old_arr = this_history_user;
        // console.log(end_old_arr,"已经存在的最后")
      }else{
        //若为新人 则追加原有数组以后保存
        let old = this_history_user
        var old_arr = {};
        for (let i in old) {
          old_arr[i] = old[i];//旧聊天记录根据人分组列表
        }
        //新
        let this_user_key = message.from //新用户id
        old_arr[this_user_key] = [message];//新用户聊天记录内容

        end_old_arr = old_arr
      }
      // console.log(end_old_arr,"这是循环后的需要存入缓存的列表")
      // console.log(JSON.stringify(end_old_arr),"这是循环后的需要存入缓存的列表")
      window.localStorage.setItem(this_user_me_key, JSON.stringify(end_old_arr))
    }else{
      //无则直接插入这个新人聊天记录
      let this_user_key = message.from
      let this_content = {}
      this_content[this_user_key] =  [message];
      let this_content_end = JSON.stringify(this_content)
      window.localStorage.setItem(this_user_me_key, this_content_end)
    }
    console.log(">>>语音 - 存缓存本机浏览器聊天记录END*")
    /*****存缓存本机浏览器聊天记录END****/
  },   // 收到音频消息。
  onLocationMessage: function (message) {
    pushNewMessage(message)
  },// 收到位置消息。
  onFileMessage: function (message) {
    pushNewMessage(message)
  },    // 收到文件消息。
  onCustomMessage: function (message) {
    pushNewMessage(message)
  },  // 收到自定义消息。
  onVideoMessage: function (message) {
    pushNewMessage(message)
  },     // 收到视频消息。
  onRecallMessage: function (message) {
    otherRecallMessage(message)
  },    // 收到消息撤回回执。
})
//接收的消息往store中push
const pushNewMessage = (message) => {
  store.dispatch('createNewMessage', message)
}
//收到他人的撤回指令
const otherRecallMessage = (message) => {
  console.log('>>>>>收到他人撤回', message);
  const { from, to, mid } = message;
  //单对单的撤回to必然为登陆的用户id，群组发起撤回to必然为群组id 所以key可以这样来区分群组或者单人。
  let key = to === EaseIM.conn.user ? from : to;
  console.log('>>>>>收到他人撤回', key);
  store.commit('CHANGE_MESSAGE_BODAY', { type: 'recall', key, mid });
  store.dispatch('gatherConversation', key)
}
/* 好友关系相关监听 */
const { INFORM_FROM } = informType
EaseIM.conn.addEventHandler('friendListen', {
  // 收到好友邀请触发此方法。
  onContactInvited: (data) => {
    //写入INFORM
    console.log('>>>>>>收到好友申请', data)
    submitInformData(INFORM_FROM.FRIEND, data)
  },
  // 联系人被删除时触发此方法。
  onContactDeleted: (data) => {
    //写入INFORM
    console.log('>>>>收到好友关系解散', data)
    submitInformData(INFORM_FROM.FRIEND, data)
    //取消针对好友的在线状态订阅
    presenceStatus('unsub', data.from)
    //好友关系解除重新获取好友列表
    fetchFriendList()
  },
  // 新增联系人会触发此方法。
  onContactAdded: (data) => {
    console.log('>>>>好友新增监听', data)
    submitInformData(INFORM_FROM.FRIEND, data)
    //新增好友重新获取好友列表
    fetchFriendList()

  },
  // 好友请求被拒绝时触发此方法。
  onContactRefuse: (data) => {
    //写入INFORM
    console.log('>>>>>>好友申请被拒绝', data)
    data.type = 'other_person_refuse'
    submitInformData(INFORM_FROM.FRIEND, data)
  },
  // 好友请求被同意时触发此方法。
  onContactAgreed: (data) => {
    //写入INFORM
    console.log('>>>>>对方同意了好友申请', data)
    //改掉data中的type
    data.type = 'other_person_agree'
    submitInformData(INFORM_FROM.FRIEND, data)
    //对方同意后重新获取好友列表
    fetchFriendList()
  }
})
/* 群组相关监听 */
EaseIM.conn.addEventHandler('groupEvent', {
  onGroupEvent: (groupevent) => {
    console.log('>>>>>>>收到群组事件', groupevent);
    submitInformData(INFORM_FROM.GROUP, groupevent)
  }
})

const submitInformData = (fromType, informContent) => {
  console.log('>>>submitInformData>>>', fromType, informContent)
  store.dispatch('createNewInform', { fromType, informContent })

}

/* 重新登陆 */
//读取本地EASEIM_loginUser
const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
const handleRelogin = () => {
  console.log('重新登陆')
  EaseIM.conn.open({
    user: loginUserFromStorage.user,
    accessToken: loginUserFromStorage.accessToken
  })

}
if (loginUserFromStorage?.user && loginUserFromStorage?.accessToken) {
  handleRelogin()
}

</script>
<template>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in" :duration="{ enter: 500, leave: 300 }">
      <component :is="Component" />
    </transition>
  </router-view>
  <!-- 铃声标签 -->
  <audio id="ring" :src="ring" controls hidden></audio>
</template>

<style type="scss">
@import './styles/reset/reset.css';
@import './styles/iconfont/iconfont.css';

/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0.3;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
} */
</style>
